import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {ReactComponent as Logo} from '../assets/LogoAM2.svg';
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 15%;
    background-color: #18253f;
    position: fixed;
    z-index: 1000;
`;

const NavHome = styled(Link)`
    height: 90%;
    padding-left: 30px;
`;

const NavLinkContainer = styled.div`
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;

    @media (max-width: 768px) {
        display: none;
    }
`;

const NavLink = styled(Link)`
    text-decoration: none;
    color: #fff;
    padding: 10px 5px;
    margin: 0 10px;
    position: relative;
    text-transform: uppercase;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 3px;
        background-color: #7d879a;
        transition: width 0.5s ease;
    }

    &:hover::after {
        width: 100%;
    }

    &.active::after {
        width: 100%;
        background-color: #e7e7e7;
    }

`;

const PlaceholderDiv = styled.div`
`;

const MobileNavLinkContainer = styled.div`
    @media (min-width: 768px) {
        display: none;
    }
`;

const MobileNavLinkPopUp = styled.div`
    position: fixed;
    width: 100vw;
    z-index: 999;
    background-color: #27344e;
    padding: 10px 0;
    display: ${({mobileNavOpen}) => (mobileNavOpen ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
        display: none;
    }
`;

export default function NavBar() {
    const location = useLocation();
    const navContainerRef = useRef(null);
    const placeholderDivRef = useRef(null);
    const [mobileNavOpen, setMobileNavOpen] = useState(false);

    const handleMobileNavClick = () => {
        setMobileNavOpen(!mobileNavOpen);
    }

    useEffect(() => {
        const navContainerHeight = navContainerRef.current.offsetHeight;
        placeholderDivRef.current.style.height = `${navContainerHeight}px`;
    }, []);

    return (
        <>
            <NavContainer ref={navContainerRef}>
                <NavHome to="/">
                    <Logo style={{width: "100%", height: "100%"}}/>
                </NavHome>
                <NavLinkContainer>
                    <NavLink style={{textTransform: "none", fontSize: "1.4rem", fontWeight: "bold"}}
                             className={location.pathname === '/' ? 'active' : ''} to="/">
                        Troféu do Reitor
                    </NavLink>
                    <NavLink to="/jogos" className={location.pathname === '/jogos' ? 'active' : ''}>
                        Jogos
                    </NavLink>
                    <NavLink to="/inscritos" className={location.pathname === '/inscritos' ? 'active' : ''}>
                        Inscritos
                    </NavLink>
                    <NavLink to="/estatisticas" className={location.pathname === '/estatisticas' ? 'active' : ''}>
                        Estatísticas
                    </NavLink>
                    <NavLink to="/jogosAntigos" className={location.pathname === '/jogosAntigos' ? 'active' : ''}>
                        Edições Passadas
                    </NavLink>
                    {/*TODO*/}
                    {/*<NavLink to="/sobre" className={location.pathname === '/sobre' ? 'active' : ''}>*/}
                    {/*    Sobre o Troféu*/}
                    {/*</NavLink>*/}
                    {/*<NavLink to="/arquivo" className={location.pathname === '/arquive' ? 'active' : ''}>*/}
                    {/*    Arquivo*/}
                    {/*</NavLink>*/}
                </NavLinkContainer>
                <MobileNavLinkContainer>
                    <MenuRoundedIcon onClick={handleMobileNavClick}
                                     style={{color: "#fff", fontSize: "2.5rem", marginRight: "6vw"}}/>
                </MobileNavLinkContainer>
            </NavContainer>
            <PlaceholderDiv ref={placeholderDivRef}/>
            <MobileNavLinkPopUp mobileNavOpen={mobileNavOpen}>
                <NavLink style={{textTransform: "none", fontSize: "1.4rem", fontWeight: "bold"}}
                         className={location.pathname === '/' ? 'active' : ''} to="/"
                         onClick={handleMobileNavClick}>
                    Troféu do Reitor
                </NavLink>
                <NavLink to="/jogos" className={location.pathname === '/jogos' ? 'active' : ''}
                         onClick={handleMobileNavClick}>
                    Jogos
                </NavLink>
                <NavLink to="/inscritos" className={location.pathname === '/inscritos' ? 'active' : ''}
                         onClick={handleMobileNavClick}>
                    Inscritos
                </NavLink>
                <NavLink to="/estatisticas"
                         className={location.pathname === '/estatisticas' ? 'active' : ''}
                         onClick={handleMobileNavClick}>
                    Estatísticas
                </NavLink>
                <NavLink to="/jogosAntigos"
                         className={location.pathname === '/jogosAntigos' ? 'active' : ''}
                         onClick={handleMobileNavClick}>
                    Edições Passadas
                </NavLink>
            </MobileNavLinkPopUp>
        </>
    );
}

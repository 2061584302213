import React, {useEffect, useState} from "react";
import Classification from "../components/Classification";
import HomeCarousel from "../components/HomeCarousel";
import CustomActiveGameCard from "../components/reusableComponents/customActiveGameCard";
import CustomCard from "../components/reusableComponents/customCard";
import CustomContainer from "../components/reusableComponents/customContainer";
import CustomClassificationGameCard from "../components/reusableComponents/customClassificationGameCard";
import {
    fetchActiveGamesData,
    fetchFinalGamesData,
    fetchGamesData,
    fetchSemiFinalGamesData
} from "../fetch/fetchTournamentData";
import styled from "styled-components";
import Footer from "../layout/Footer";
import {alignProperty} from "@mui/material/styles/cssUtils";

const HomeTitle = styled.h1`
    margin-top: 150px;
    font-weight: 600;
    font-size: 2.7rem;
    font-family: "Poppins", sans-serif;
    color: #002639;

    @media (max-width: 768px) {
        margin-top: 50px;
    }
`;

export default function Home() {
    const [activeGamesData, setActiveGamesData] = useState(null);
    const [classificationLoading, setClassificationLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [games, setGames] = useState([]);
    const [finalGamesData, setFinalGamesData] = useState(null);
    const [semiFinalGamesData, setSemiFinalGamesData] = useState(null);

    useEffect(() => {
        fetchActiveGamesData().then((jsonData) => {
            setActiveGamesData(jsonData);
        }).catch((error) => {
            console.error('Error fetching data:', error);
        });

        fetchFinalGamesData().then((jsonData) => {
            setFinalGamesData(jsonData);
        }).catch((error) => {
            console.error('Error fetching data:', error);
        });

        fetchSemiFinalGamesData().then((jsonData) => {
            setSemiFinalGamesData(jsonData);
        }).catch((error) => {
            console.error('Error fetching data:', error);
        });

        fetchGamesData().then((jsonData) => {
            setGames(jsonData);
        }).catch((error) => {
            console.error('Error fetching data:', error);
        });

    }, []);

    useEffect(() => {
        if (activeGamesData && finalGamesData && semiFinalGamesData) {
            setLoading(false);
        }
    }, [activeGamesData, finalGamesData, semiFinalGamesData]);

    //jogos que já acabaram e nao estão ativos
    const finishedGames = games.filter(game => game.resultados === null && game.ativo === "0");



    return (
        <>
            <HomeCarousel/>
            <CustomContainer>

                {!loading && (
                    <div style={{display: "flex", gap: "3%", flexWrap: "wrap", paddingTop: "70px"}}>

                        {!classificationLoading ? (
                                <div style={{flex: "1 1 57%"}}>
                                    <CustomCard style={{width: "100%", marginBottom: "30px"}}>
                                        <h3 style={{margin: "0"}}>Jogo ativo</h3>
                                        {activeGamesData.length > 0 ? (
                                                activeGamesData.map((game, index) => (
                                                    <CustomActiveGameCard key={index} data={game} width="100%"/>
                                                ))
                                            ) :
                                            <p style={{textAlign: "center"}}>Não existem jogos ativos</p>}
                                    </CustomCard>
                                    <CustomCard>
                                        <h3 style={{margin: "0"}}>Próximos Jogos</h3>

                                        {games.length > 0 ? (
                                                <>
                                                    {games.length > 0 && (
                                                        <div>
                                                            {finishedGames.slice(0, 2).map((game, index) => (
                                                                <CustomClassificationGameCard key={index} data={game}
                                                                                              scorers={true}
                                                                                              width="100%"/>
                                                            ))}
                                                        </div>
                                                    )}
                                                </>
                                            ) :
                                            <p style={{textAlign: "center"}}>Não existem jogos</p>}
                                    </CustomCard>
                                </div>
                            ) :
                            <p style={{textAlign: "center", flex: "1 1 57%", paddingBottom: "300px"}}>Os grupos para a próxima edição ainda não
                                foram sorteados.</p>}

                        <Classification style={{flex: "1 1 40%", marginBottom: "20px"}}
                                        onLoadingChange={setClassificationLoading}/>
                    </div>
                )}
            </CustomContainer>
            {!loading && !classificationLoading}
            <Footer/>
        </>

    );
}






import React, { useEffect, useState } from 'react';
import CustomContainer from "../components/reusableComponents/customContainer";
import CustomTable from "../components/reusableComponents/customTable";
import CustomCard from "../components/reusableComponents/customCard";
import {
    fetchTop10CardsData_Last,
    fetchTop10ScorersData_Last,
    fetchTopGuardData_Last,
    fetchTopScoringTeamsData_Last
} from "../fetch/fetchTournamentData";
import styled from "styled-components";
import Classification from "../components/Classification_previous";
import Footer from "../layout/Footer";

const DoubleCardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4%;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const GridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
`;

const MainContentContainer = styled.div`
    flex: 1 1 67%;
    margin-bottom: 25px;
`;


export default function PreviousGames() {
    const [scorersData, setScorersData] = useState(null);
    const [guardsData, setGuardsData] = useState(null);
    const [cardsData, setCardsData] = useState(null);
    const [topScoringTeamsData, setTopScoringTeamsData] = useState(null);

    const [descriptions, setDescriptions] = useState([]); // Novo estado para descrições
    const [selectedDescription, setSelectedDescription] = useState(""); // Estado para descrição selecionada

    //const [loading, setLoading] = useState(true);

    const [loading, setLoading] = useState(true);
    const [classificationLoading, setClassificationLoading] = useState(true);
    const [idSelect, setIdSelect] = useState(null);
    const [penultimoId, setPenultimoId]  = useState(null); //// implementar a api para receber este valor




    useEffect(() => {
        // Fetch Descriptions
        fetch('https://api.amadeira.pt/api/tr/lastEdition/selectTorneio/')  // Sua API de descrições
            .then((response) => response.json())
            .then((data) => {
                console.log('Descriptions Data:', data);

                setDescriptions(data); // Salva as descrições no estado
            })
            .catch((error) => {
                console.error('Error fetching descriptions:', error);
            });
////fecth para o penultimo id

        fetch('https://api.amadeira.pt/api/tr/lastEdition/penultimoId/') // Substitua pela URL correta da sua API
            .then((response) => response.json())
            .then((data) => {
                console.log('Dados recebidos da API:', data);

                if (data && data.length > 0) {
                    const penultimoId = data[0].id; // Pegue o penultimoId
                    setPenultimoId(penultimoId);  // Atualize o penultimoId no estado
                    setSelectedDescription(penultimoId);  // Atualize o valor selecionado no dropdown para ser o penultimoId
                    setIdSelect(penultimoId);  // Opcional, se você precisar usar o id também para outra lógica
                } else {
                    console.error('Nenhum dado foi retornado da API.');
                }
            })
            .catch((error) => {
                console.error('Erro ao buscar dados da API:', error);
            });



        // Fetch Scorers Data


        // Fetch Guards Data


        // Fetch Cards Data


        // Fetch Top Scoring Teams Data

    }, []);

    useEffect(() => {
        if (penultimoId) {
            fetchTopGuardData_Last(penultimoId)
                .then((jsonData) => {
                    console.log('Dados retornados pela API para penultimoId:', penultimoId, jsonData);
                    setGuardsData(jsonData);  // Atualize o estado com os dados recebidos
                })
                .catch((error) => {
                    console.error('Erro ao buscar dados com penultimoId:', error);
                });

            //////
            fetchTop10ScorersData_Last(penultimoId).then((jsonData) => {
                console.log('Scorers Data:', jsonData);
                setScorersData(jsonData);
            }).catch((error) => {
                console.error('Error fetching scorers data:', error);
            });

            /////
            fetchTop10CardsData_Last(penultimoId).then((jsonData) => {
                console.log('Cards Data:', jsonData);
                setCardsData(jsonData);
            }).catch((error) => {
                console.error('Error fetching cards data:', error);
            });

            ///////

            fetchTopScoringTeamsData_Last(penultimoId).then((jsonData) => {
                console.log('Atack Data:', jsonData);
                setTopScoringTeamsData(jsonData);
            }).catch((error) => {
                console.error('Error fetching top scoring teams data:', error);
            });
        }
    }, [penultimoId]);

    useEffect(() => {
        if (idSelect) {

            fetchTopGuardData_Last(idSelect).then((jsonData) => {
                console.log('Dados retornados pela API para id:', idSelect, jsonData);
                setGuardsData(jsonData);  // Atualize o estado com os dados recebidos
            }).catch((error) => {
                console.error('Erro ao buscar dados:', error);
            });

            ///// top10 jogadores ////

            fetchTop10ScorersData_Last(idSelect).then((jsonData) => {
                console.log('Scorers Data:', jsonData);
                setScorersData(jsonData);
            }).catch((error) => {
                console.error('Error fetching scorers data:', error);
            });

                /////// top10 equipas a marcar ////
            fetchTopScoringTeamsData_Last(idSelect).then((jsonData) => {
                console.log('Atack Data:', jsonData);
                setTopScoringTeamsData(jsonData);
            }).catch((error) => {
                console.error('Error fetching top scoring teams data:', error);
            });

            ///// cartoes ////

            fetchTop10CardsData_Last(idSelect).then((jsonData) => {
                console.log('Cards Data:', jsonData);
                setCardsData(jsonData);
            }).catch((error) => {
                console.error('Error fetching cards data:', error);
            });

        }
    }, [idSelect]);

    useEffect(() => {
        if (scorersData && guardsData && cardsData && topScoringTeamsData) {
            setLoading(false);
        }

    }, [scorersData, guardsData, cardsData, topScoringTeamsData]);

    const handleSelectChange = (event) => {
        const selectedId = event.target.value;
        console.log('Novo ID selecionado:', selectedId);  // Verifique se o ID selecionado está correto
        setSelectedDescription(selectedId);  // Atualiza a descrição selecionada
        setIdSelect(selectedId);

    };
    console.log('Torneio selecionado ID:', idSelect);
    return (
        <>

            <CustomContainer>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h1 style={{marginRight: '10px'}}>Torneio:</h1>
                    <label>
                        <select
                            name="selectedDescription"
                            onChange={handleSelectChange}
                            value={selectedDescription}
                            style={{fontSize: '1.5rem', fontFamily: 'Poppins, sans-serif', fontWeight: '600'}}
                        >
                            {/*<option value="" style={{fontSize: '0.5rem'}}>Selecione uma opção</option>*/}

                            {descriptions.map((desc) => (
                                <option key={desc.id} value={desc.id} style={{fontSize: '0.5rem'}}>
                                    {desc.description}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>

                {!loading && (
                    <GridContainer>

                            <MainContentContainer>
                                <CustomCard>
                                    <h3>Melhores marcadores</h3>

                                    <CustomTable
                                        data={scorersData}
                                        headers={[
                                            {label: '', key: 'posicao', align: 'center'},
                                            {label: 'Nome', key: 'nome'},
                                            {label: 'Equipa', key: 'nome_equipa'},
                                            {label: 'Golos', key: 'golos', align: 'center'},
                                        ]}
                                        width="100%"
                                    />

                                </CustomCard>
                                <DoubleCardContainer>
                                    <CustomCard style={{marginTop: "25px", width: "48%"}}>
                                        <h3>Melhores equipas defensivas</h3>

                                        <CustomTable
                                            data={guardsData}
                                            headers={[
                                                {label: '', key: 'posicao', align: 'center'},
                                                {label: 'Equipa', key: 'nome'},
                                                {label: 'Golos sofridos', key: 'golos_Sofridos', align: 'center'},
                                            ]}
                                        />

                                    </CustomCard>
                                    <CustomCard style={{marginTop: "25px", width: "48%"}}>
                                        <h3>Melhores equipas atacantes</h3>

                                        <CustomTable
                                            data={topScoringTeamsData}
                                            headers={[
                                                {label: '', key: 'posicao', align: 'center'},
                                                {label: 'Equipa', key: 'equipa'},
                                                {label: 'Golos marcados', key: 'golos', align: 'center'},
                                            ]}
                                        />

                                    </CustomCard>
                                </DoubleCardContainer>
                                <CustomCard style={{marginTop: "25px"}}>
                                    <h3>Mais cartões</h3>
                                    <CustomTable
                                        data={cardsData}
                                        headers={[
                                            {label: '', key: 'posicao', align: 'center'},
                                            {label: 'Nome', key: 'name'},
                                            {label: 'Equipa', key: 'team_name'},
                                            {label: 'Amarelos', key: 'yellow_card', align: 'center'},
                                            {label: 'Vermelhos', key: 'red_card', align: 'center'},
                                        ]}
                                    />

                                </CustomCard>
                            </MainContentContainer>

                        <Classification penultimoId={penultimoId} idSelect={idSelect}
                                        showEliminationGames={true} showActiveGames={true}
                                        style={{flex: "1 1 30%", marginBottom: "20px"}}
                                        onLoadingChange={setClassificationLoading}/>
                    </GridContainer>
                )}
            </CustomContainer>
            {!loading && !classificationLoading && <Footer/>}
        </>
    );
}

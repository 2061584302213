import React, {useEffect, useState} from 'react';
import CustomTable from "./reusableComponents/customTable";
import CustomClassificationGameCard from "./reusableComponents/customClassificationGameCard";
import CustomCard from "./reusableComponents/customCard";
import {
    fetchClassificationData,
    fetchFinalGamesData_Last,
    fetchSemiFinalGamesData_Last,
    fetchActiveGamesData_Last,
    fetchTopGuardData_Last,
    fetchTop10ScorersData_Last,
    fetchTop10CardsData_Last,
    fetchTopScoringTeamsData_Last
} from "../fetch/fetchTournamentData";
import CustomActiveGameCard from "./reusableComponents/customActiveGameCard";

export default function ClassificationLast({width, onLoadingChange, style, showActiveGames, showEliminationGames, penultimoId, idSelect }) {
    const [loading, setLoading] = useState(true);

    const [classificationGroupBData, setClassificationGroupBData] = useState(null);
    const [classificationGroupAData, setClassificationGroupAData] = useState(null);
    const [finalGamesData, setFinalGamesData] = useState(null);
    const [semiFinalGamesData, setSemiFinalGamesData] = useState(null);
    const [activeGamesData, setActiveGamesData] = useState(null);

    useEffect(() => {
        fetchClassificationData().then((jsonData) => {
            setClassificationGroupAData(jsonData.groupA);
            setClassificationGroupBData(jsonData.groupB);
        }).catch((error) => {
            console.error('Error fetching data:', error);
        });


    }, []);

    useEffect(() => {
        if (penultimoId) {

            if (showEliminationGames) {
                fetchFinalGamesData_Last(penultimoId).then((jsonData) => {
                    setFinalGamesData(jsonData);
                }).catch((error) => {
                    console.error('Error fetching data:', error);
                });

                fetchSemiFinalGamesData_Last(penultimoId).then((jsonData) => {
                    setSemiFinalGamesData(jsonData);
                }).catch((error) => {
                    console.error('Error fetching data:', error);
                });
            } else {
                setFinalGamesData([]);
                setSemiFinalGamesData([]);
            }

            if (showActiveGames) {
                fetchActiveGamesData_Last(penultimoId).then((jsonData) => {
                    setActiveGamesData(jsonData);
                }).catch((error) => {
                    console.error('Error fetching data:', error);
                });
            } else {
                setActiveGamesData([]);
            }
        }
    }, [penultimoId]);



    useEffect(() => {
        if (idSelect) {

            if (showEliminationGames) {
                fetchFinalGamesData_Last(idSelect).then((jsonData) => {
                    setFinalGamesData(jsonData);
                }).catch((error) => {
                    console.error('Error fetching data:', error);
                });

                fetchSemiFinalGamesData_Last(idSelect).then((jsonData) => {
                    setSemiFinalGamesData(jsonData);
                }).catch((error) => {
                    console.error('Error fetching data:', error);
                });
            } else {
                setFinalGamesData([]);
                setSemiFinalGamesData([]);
            }

            if (showActiveGames) {
                fetchActiveGamesData_Last(idSelect).then((jsonData) => {
                    setActiveGamesData(jsonData);
                }).catch((error) => {
                    console.error('Error fetching data:', error);
                });
            } else {
                setActiveGamesData([]);
            }
        }
    }, [idSelect]);




    useEffect(() => {
        if (classificationGroupAData && activeGamesData && classificationGroupBData && finalGamesData && semiFinalGamesData) {
            setLoading(false);
            onLoadingChange(false);
        }
    }, [classificationGroupAData, activeGamesData, classificationGroupBData, finalGamesData, semiFinalGamesData]);


    return (
        <>
            {!loading && (

                <CustomCard style={{width: width, ...style}}>
                    {activeGamesData.length > 0 && showActiveGames && (
                        <div style={{marginBottom: "30px"}}>
                            <h3 style={{margin: "0 0 20px 0"}}>Jogo ativo</h3>
                            {activeGamesData.map((game, index) => (
                                <CustomActiveGameCard key={index} data={game} width="100%"/>
                            ))}
                        </div>
                    )}
                    <h3>Classificação</h3>

                    {finalGamesData.length > 0 && (

                        <div style={{marginBottom: "30px"}}>
                            <p style={{margin: "25px 0 0px 0", color: "#002639"}}>Final</p>
                            {finalGamesData.map((game, index) => (
                                <CustomClassificationGameCard key={index} data={game} width="100%"/>
                            ))}
                        </div>
                    )}
                    {semiFinalGamesData.length > 0 && (
                        <div style={{marginBottom: "30px"}}>
                            <p style={{margin: "25px 0 0px 0", color: "#002639"}}>Meia Final</p>
                            {semiFinalGamesData.map((game, index) => (
                                <CustomClassificationGameCard key={index} data={game} width="100%"/>
                            ))}
                        </div>
                    )}
                    {classificationGroupAData.length > 0 && (
                        <>
                            {classificationGroupBData.length > 0 && (
                                <>
                                    <p style={{margin: "20px 0 0 0", color: "#002639"}}>Fase de Grupos</p>
                                    <h4 style={{margin: "10px 0 5px 0", color: "#002639"}}>Grupo A</h4>
                                </>)}
                            <CustomTable classification={true} data={classificationGroupAData} headers={[
                                {label: '', key: 'posicao', align: 'center'},
                                {label: 'Nome', key: 'nome'},
                                {label: 'Pontos', key: 'pontos', align: 'center'},
                            ]}/>
                        </>
                    )}
                    {classificationGroupBData.length > 0 && (
                        <>
                            <h4 style={{margin: "20px 0 5px 0", color: "#002639"}}>Grupo B</h4>
                            <CustomTable classification={true} data={classificationGroupBData} headers={[
                                {label: '', key: 'posicao', align: 'center'},
                                {label: 'Nome', key: 'nome'},
                                {label: 'Pontos', key: 'pontos', align: 'center'},
                            ]}/>
                        </>
                    )}
                </CustomCard>


            )}
        </>
    );
}
import {changeNameFormat, calculateClassification} from '../utils/fetchAuxFunctions';

export const fetchClassificationData = async () => {
    try {
        const jsonData = await fetchTeamsData();

        const GroupAData = jsonData.filter(item => item.grupo === "Grupo A");
        const filteredGroupAData = calculateClassification(GroupAData);

        const GroupBData = jsonData.filter(item => item.grupo === "Grupo B");
        const filteredGroupBData = calculateClassification(GroupBData);


        return {groupA: filteredGroupAData, groupB: filteredGroupBData};
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const fetchTop10CardsData = async () => {
    try {
        const response = await fetch('https://api.amadeira.pt/api/tr/discipline/');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        const filteredData = jsonData.filter(item => (item.red_card + item.yellow_card) >= 0);
        filteredData.sort((a, b) => (b.red_card + b.yellow_card) - (a.red_card + a.yellow_card));
        filteredData.forEach((item, index) => {
            item.posicao = (index + 1) + ".";
            item.name = item.name.replace(/([a-z])([A-Z])/g, '$1 $2')
            item.name = changeNameFormat(item.name);
        });

        return filteredData.slice(0, 10);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}


export const fetchTop10ScorersData = async () => {
    try {
        const response = await fetch('https://api.amadeira.pt/api/tr/scorers/');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();


        const filteredData = jsonData.filter(item => item.golos >= 0);
        filteredData.sort((a, b) => b.golos - a.golos);
        filteredData.forEach((item, index) => {
            item.posicao = (index + 1) + ".";
        });

        return filteredData.slice(0, 10);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


export const fetchTop10CardsData_Last = async (selectedId) => {
    try {
        const response = await fetch(`https://api.amadeira.pt/api/tr/lastEdition/cards/?quota_id=${selectedId}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        const filteredData = jsonData.filter(item => (item.red_card + item.yellow_card) >= 0);
        filteredData.sort((a, b) => (b.red_card + b.yellow_card) - (a.red_card + a.yellow_card));
        filteredData.forEach((item, index) => {
            item.posicao = (index + 1) + ".";
            item.name = item.name.replace(/([a-z])([A-Z])/g, '$1 $2')
            item.name = changeNameFormat(item.name);
        });

        return filteredData.slice(0, 10);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const fetchTop10ScorersData_Last = async (selectedId) => {
    try {
        const response = await fetch(`https://api.amadeira.pt/api/tr/lastEdition/scorers/?quota_id=${selectedId}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();


        const filteredData = jsonData.filter(item => item.golos >= 0);
        filteredData.sort((a, b) => b.golos - a.golos);
        filteredData.forEach((item, index) => {
            item.posicao = (index + 1) + ".";
        });

        return filteredData.slice(0, 10);


    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const fetchTopGuardData_Last = async (selectedId) => {
    try {
        const response = await fetch(`https://api.amadeira.pt/api/tr/lastEdition/btDefense/?quota_id=${selectedId}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        // Utilizamos um Set para garantir que cada equipe seja única
        const uniqueTeams = [];
        const seenTeams = new Set();
        jsonData.sort((a, b) => a.golos - b.golos);
        jsonData.forEach(item => {
            if (!seenTeams.has(item.nome)) {
                uniqueTeams.push(item);  // Adiciona o item se a equipe ainda não foi vista
                seenTeams.add(item.nome); // Marca a equipe como vista
            }

        });

        const limitedTeams = uniqueTeams.slice(0, 6);// limita para aparecerem apenas 6 equipas

        limitedTeams.forEach((item, index) => {
            item.posicao = (index + 1) + ".";  // Corrige a numeração
        });
        return limitedTeams;

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const fetchTopScoringTeamsData_Last = async (selectedId) => {
    try {
        const response = await fetch(`https://api.amadeira.pt/api/tr/lastEdition/btAtack/?quota_id=${selectedId}`);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const data = await response.json();

        const teamGoals = {};

        data.forEach(player => {
            const teamName = player.nome_equipa;
            const goals = parseInt(player.golos);

            if (teamName in teamGoals) {
                teamGoals[teamName].golos += goals;
            } else {
                teamGoals[teamName] = {'equipa': player.nome_equipa, 'golos': goals};
            }
        });

        const teamGoalsArray = Object.values(teamGoals);

        teamGoalsArray.sort((a, b) => b.golos - a.golos);
        teamGoalsArray.forEach((item, index) => {
            item.posicao = (index + 1) + ".";
        });

        return teamGoalsArray;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const fetchTopGuardData = async () => {
    try {
        const response = await fetch('https://api.amadeira.pt/api/tr/guards/');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        // Utilizamos um Set para garantir que cada equipe seja única
        const uniqueTeams = [];
        const seenTeams = new Set();
        jsonData.sort((a, b) => a.golos - b.golos);
        jsonData.forEach(item => {
            if (!seenTeams.has(item.nome)) {
                uniqueTeams.push(item);  // Adiciona o item se a equipe ainda não foi vista
                seenTeams.add(item.nome); // Marca a equipe como vista
            }
        });
        const limitedTeams = uniqueTeams.slice(0, 6); // limita para aparecerem apenas 6 equipas
        return limitedTeams;

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}




export const fetchTopScoringTeamsData = async () => {
    try {
        const response = await fetch('https://api.amadeira.pt/api/tr/scorers/');
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const data = await response.json();

        const teamGoals = {};

        data.forEach(player => {
            const teamName = player.nome_equipa;
            const goals = parseInt(player.golos);

            if (teamName in teamGoals) {
                teamGoals[teamName].golos += goals;
            } else {
                teamGoals[teamName] = {'equipa': player.nome_equipa, 'golos': goals};
            }
        });

        const teamGoalsArray = Object.values(teamGoals);

        teamGoalsArray.sort((a, b) => b.golos - a.golos);
        teamGoalsArray.forEach((item, index) => {
            item.posicao = (index + 1) + ".";
        });

        return teamGoalsArray;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const fetchPlayersData = async () => {
    try {
        const responseDiscipline = await fetch('https://api.amadeira.pt/api/tr/discipline/');
        const responseScorers = await fetch('https://api.amadeira.pt/api/tr/scorers/');
        if (!responseDiscipline.ok || !responseScorers.ok) {
            throw new Error('Network response was not ok');
        }

        const jsonDataDiscipline = await responseDiscipline.json();
        const jsonDataScorers = await responseScorers.json();

        const players = {};

        jsonDataDiscipline.forEach(player => {
            player.name = player.name.replace(/([a-z])([A-Z])/g, '$1 $2')
            player.name = changeNameFormat(player.name);
            const playerId = player.id;
            const yellowCards = parseInt(player.yellow_card);
            const redCards = parseInt(player.red_card);

            if (playerId in players) {
                players[playerId].yellow_card += yellowCards;
                players[playerId].red_card += redCards;
            } else {
                players[playerId] = {
                    'nome': player.name,
                    'equipa': player.team_name,
                    'golos': 0,
                    'cartoes_amarelos': yellowCards,
                    'cartoes_vermelhos': redCards
                };
            }
        });

        jsonDataScorers.forEach(player => {
            player.nome = changeNameFormat(player.nome);
            const playerId = player.id;
            const golos = parseInt(player.golos);

            if (playerId in players) {
                players[playerId].golos += golos;
            } else {
                throw new Error('Player not found in discipline data');
            }
        })

        return Object.values(players);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const fetchTeamsData = async () => {
    try {
        const responseClassification = await fetch('https://api.amadeira.pt/api/tr/classification/');
        const responseGuard = await fetch('https://api.amadeira.pt/api/tr/guards/');

        if (!responseClassification.ok || !responseGuard.ok) {
            throw new Error('Network response was not ok');
        }

        const classificationJsonData = await responseClassification.json();
        const guardJsonData = await responseGuard.json();

        console.log(classificationJsonData);

        const playersData = await fetchPlayersData();

        const teams = {};

        classificationJsonData.forEach((team) => {
            const teamName = team.nome;
            const points = parseInt(team.pontos);
            if (team.grupo === "GrupoA") {
                team.grupo = "Grupo A";
            } else if (team.grupo === "GrupoB") {
                team.grupo = "Grupo B";
            }

            teams[teamName] = {
                'nome': teamName,
                'pontos': points,
                'grupo': team.grupo,
                'golos_sofridos': 0,
                'golos_marcados': 0,
                'cartoes_amarelos': 0,
                'cartoes_vermelhos': 0
            };
        });
        guardJsonData.forEach((team) => {
            const teamName = team.nome;
            const sufferedGoals = parseInt(team.golos);

            if (teamName in teams) {
                teams[teamName].golos_sofridos = sufferedGoals;
            } else {
                throw new Error('Team not found in classification data');
            }
        });

        playersData.forEach(player => {
            const teamName = player.equipa;
            const scoredGoals = parseInt(player.golos);
            const yellowCards = parseInt(player.cartoes_amarelos);
            const redCards = parseInt(player.cartoes_vermelhos);

            if (teamName in teams) {
                teams[teamName].golos_marcados += scoredGoals;
                teams[teamName].cartoes_amarelos += yellowCards;
                teams[teamName].cartoes_vermelhos += redCards;
            }
        });

        return Object.values(teams);
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export const fetchSemiFinalGamesData = async () => {
    try {
        const response = await fetch('https://api.amadeira.pt/api/tr/game/');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        return jsonData.filter(item => item.fase === 'Meia Final');

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const fetchFinalGamesData = async () => {
    try {
        const response = await fetch('https://api.amadeira.pt/api/tr/game/');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        return jsonData.filter(item => item.fase === 'Final');

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const fetchTournamentName = async () => {
    try {
        const response = await fetch('https://api.amadeira.pt/api/tr/scorers/');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        return "Jogos do " + jsonData[0]?.description;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const fetchActiveGamesData = async () => {
    try {
        const response = await fetch('https://api.amadeira.pt/api/tr/game/');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        return jsonData.filter(item => item.ativo === "1");
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const fetchGamesData = async () => {
    try {
        const response = await fetch('https://api.amadeira.pt/api/tr/game/');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        return jsonData;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}


///// fecth para o componete Classification_prevous

export const fetchClassificationData_Last = async (idSelected) => {
    try {
        const jsonData = await fetchTeamsData_Last(idSelected);

        const GroupAData = jsonData.filter(item => item.grupo === "Grupo A");
        const filteredGroupAData = calculateClassification(GroupAData);

        const GroupBData = jsonData.filter(item => item.grupo === "Grupo B");
        const filteredGroupBData = calculateClassification(GroupBData);


        return {groupA: filteredGroupAData, groupB: filteredGroupBData};
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


export const fetchTeamsData_Last = async (idSelected) => {
    try {
        const responseClassification = await fetch('https://api.amadeira.pt/api/tr/classification/'); /// fazer aqui uma nova api -> pode ser na pasta Last
        const responseGuard = await fetch(`https://api.amadeira.pt/api/tr/lastEdition/btDefense/?quota_id=${idSelected}`);

        if (!responseClassification.ok || !responseGuard.ok) {
            throw new Error('Network response was not ok');
        }

        const classificationJsonData = await responseClassification.json();
        const guardJsonData = await responseGuard.json();

        console.log(classificationJsonData);

        const playersData = await fetchPlayersData();

        const teams = {};

        classificationJsonData.forEach((team) => {
            const teamName = team.nome;
            const points = parseInt(team.pontos);
            if (team.grupo === "GrupoA") {
                team.grupo = "Grupo A";
            } else if (team.grupo === "GrupoB") {
                team.grupo = "Grupo B";
            }

            teams[teamName] = {
                'nome': teamName,
                'pontos': points,
                'grupo': team.grupo,
                'golos_sofridos': 0,
                'golos_marcados': 0,
                'cartoes_amarelos': 0,
                'cartoes_vermelhos': 0
            };
        });
        guardJsonData.forEach((team) => {
            const teamName = team.nome;
            const sufferedGoals = parseInt(team.golos);

            if (teamName in teams) {
                teams[teamName].golos_sofridos = sufferedGoals;
            } else {
                throw new Error('Team not found in classification data');
            }
        });

        playersData.forEach(player => {
            const teamName = player.equipa;
            const scoredGoals = parseInt(player.golos);
            const yellowCards = parseInt(player.cartoes_amarelos);
            const redCards = parseInt(player.cartoes_vermelhos);

            if (teamName in teams) {
                teams[teamName].golos_marcados += scoredGoals;
                teams[teamName].cartoes_amarelos += yellowCards;
                teams[teamName].cartoes_vermelhos += redCards;
            }
        });

        return Object.values(teams);
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export const fetchFinalGamesData_Last = async (idSelected) => {
    try {
        const response = await fetch(`https://api.amadeira.pt/api/tr/lastEdition/game_last/?quota_id=${idSelected}`); /// mais um api aqui, na pasta Last
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        return jsonData.filter(item => item.fase === 'Final');

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const fetchSemiFinalGamesData_Last = async (idSelected) => {
    try {
        const response = await fetch(`https://api.amadeira.pt/api/tr/lastEdition/game_last/?quota_id=${idSelected}`); /// usar a mesma api de cima
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        return jsonData.filter(item => item.fase === 'Meia Final');

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const fetchActiveGamesData_Last = async (idSelected) => {
    try {
        const response = await fetch(`https://api.amadeira.pt/api/tr/lastEdition/game_last/?quota_id=${idSelected}`); /// mesma api que antes
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        return jsonData.filter(item => item.ativo === "1");
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}